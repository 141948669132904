<template>
  <div style="margin: -15px -15px;">
    <a-spin :spinning="spinning">
      <!-- 搜索框 -->
      <div class="registerMember-right-top">
        <!--   顶部搜索  -->
        <div style="height: 70px;;display: flex;align-items: center; border-bottom: 10px solid #f5f5f5;width:100%;padding:0 15px">
          <div class="registerMember-right-top-input">
           <span>手机号码：</span> 
            <a-input
              v-model="searchForm.number"
              style="width: 30%;margin-right:30px"
              @pressEnter="enterBtn"
              placeholder="请输入"
            />

            姓名：
            <a-input
              v-model="searchForm.name"
              style="width: 30%"
              @pressEnter="enterBtn"
              placeholder="请输入"
            />
          </div>
          <!-- <div class="registerMember-right-top-input">
            姓名:
            <a-input
              v-model="searchForm.name"
              style="width: 60%"
              @pressEnter="enterBtn"
              placeholder="请输入"
            />
          </div> -->
          <!-- <div class="registerMember-right-top-input">
            职位:
            <a-select
                show-search
                option-filter-prop="children"
                placeholder="请选择"
                v-model="identityValue"
                style="width: 150px"
                @change="selectIdentityType"
              >
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="运营">
                  运营
                </a-select-option>
                <a-select-option value="产品经理">
                  产品经理
                </a-select-option>
                <a-select-option value="直播专员">
                  直播专员
                </a-select-option>
                <a-select-option value="财务">
                  财务
                </a-select-option>
                <a-select-option value="前端">
                  前端
                </a-select-option>
                <a-select-option value="后端"> 
                  后端 
                </a-select-option>
                <a-select-option value="测试"> 
                  测试 
                </a-select-option>
              </a-select>
          </div> -->
          <div class="registerMember-right-top-input" >
            状态:
            <a-radio-group
              style="margin-left: 5px;margin-right:10px"
              v-model="searchForm.certStatus"
              @change="enterBtn"
            >
              <a-radio-button value=""> 全部 </a-radio-button>
              <a-radio-button value="CERTIFIED"> 已认证 </a-radio-button>
              <!-- <a-radio-button value="IN_REVIEW"> 待审核 </a-radio-button> -->
              <a-radio-button value="UNCERTIFIED"> 未认证 </a-radio-button>
            </a-radio-group>
          </div>
          <div>
            <a-button @click="selectBtn" style="margin-left: 20px" type="primary"
            >查询</a-button
            >
            <a-button @click="restBtn" style="margin-left: 10px"
            >重置</a-button
            >
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="registerMember-right-table" style="padding: 20px 15px 0 15px">
        <!--  列表上方按钮      -->
        <div class="registerMember-right-table-top-btn" style=" height: 50px;">
          <a-button @click="addBtn" type="primary">新增</a-button>
        </div>
        <!--   列表   -->
        <div class="registerMember-right-table-top-table">
          <a-table
            :scroll="{ x: 1500 }"
            :pagination="false"
            style="width: 99%"
            :columns="columns"
            :rowKey="(record) => record.id"
            :data-source="tableList"
          >
            <span slot="isSummit" slot-scope="text, record">
              <span>{{ text === 1?'已完善':'未完善' }}</span>
            </span>
            <span slot="certStatus" slot-scope="text, record">
              <div v-if="text === 'CERTIFIED'">
                <admin_con
                  style="color: #02ac27"
                  class="icon"
                  type="icon-yuandian1"
                />已认证
              </div>
              <div v-if="text === 'IN_REVIEW'">
                <admin_con
                  style="color: #0e77d1"
                  class="icon"
                  type="icon-yuandian1"
                />待审核
              </div>
              <div v-if="text === 'UNCERTIFIED'">
                <admin_con
                  style="color: #a1a3a2"
                  class="icon"
                  type="icon-yuandian1"
                />未认证
              </div>
            </span>
            <span slot="operation" slot-scope="text, record">
              <a @click="sign(record)" style="margin-right:5px">标记</a>
              <a-popconfirm
                placement="top"
                ok-text="是"
                cancel-text="否"
                @confirm="cancellation(record.id)"
              >
                <template slot="title">
                  <p>是否确认注销？</p>
                </template>
                <a>注销账号</a>
              </a-popconfirm>
              <!-- <a @click="amendBtnTable(record.id)" >修改</a> -->
              <!-- <a @click="ViewRecords(record.id)" style="margin-left: 8px">查看更新记录</a> -->
            </span>
          </a-table>
        </div>
        <!--      分页-->
        <div style="width: 100%">
          <div class="pageClass">
            <!-- <a-pagination
              :current="pageNo"
              @change="pageChange"
              :total="total"
              show-quick-jumper
              show-size-changer
            /> -->
            <a-pagination show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
              :total=pagination.total v-model="pagination.current"  @change="pageChange" @showSizeChange="pageChange"
            />
          </div>
        </div>
      </div>

      <!-- 新增框 -->
      <a-drawer
        title="新增"
        placement="right"
        :closable="false"
        :visible="addVisible"
        @close="addClose"
        width="500"
      >
        <div class="addForms">
          <a-form-model
            ref="addRuleForm"
            :model="addForm"
            :rules="rules3"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >

            <a-form-model-item label="职位" prop="post">
              <a-select
                show-search
                option-filter-prop="children"
                placeholder="请选择"
                v-model="addForm.post"
                style="width: 300px"
              >
                <a-select-option value="运营">
                  运营
                </a-select-option>
                <a-select-option value="产品经理">
                  产品经理
                </a-select-option>
                <a-select-option value="直播专员">
                  直播专员
                </a-select-option>
                <a-select-option value="财务">
                  财务
                </a-select-option>
                <a-select-option value="前端">
                  前端
                </a-select-option>
                <a-select-option value="后端"> 
                  后端 
                </a-select-option>
                <a-select-option value="测试"> 
                  测试 
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name">
              <a-input
                style="width: 300px"
                v-model="addForm.name"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="tel">
              <a-input
                style="width: 300px"
                v-model="addForm.tel"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="省份/城市" prop="province">
              <a-cascader
                style="width: 300px"
                v-model="addForm.province"
                :options="hospitaloPtions"
                placeholder="请选择"
                @change="provinceChange"
              />
            </a-form-model-item>
            <!------------------------------- 不需要1001 ~ 1004---------------------------------->
            <!-- <a-form-model-item
              v-if="
                addForm.type === '1001' ||
                addForm.type === '1002' ||
                addForm.type === '1003' ||
                addForm.type === '1004'
              "
              label="医院"
              prop="hospitalName"
            >
              <a-select
                style="width: 300px"
                v-model="addForm.hospitalName"
                placeholder="请选择"
                @change="hospitalSeleChange"
              >
                <a-select-option
                  v-for="item in hospitalNameList"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              v-if="
                addForm.type === '1001' ||
                addForm.type === '1002' ||
                addForm.type === '1003' ||
                addForm.type === '1004'
              "
              label="科室"
              prop="hospitalOffices"
            >
              <a-cascader
                style="width: 300px"
                v-model="addForm.hospitalOffices"
                :options="hospitalOfficesList"
                placeholder="请选择"
                @change="officeChange"
              />
            </a-form-model-item>
            <a-form-model-item
              v-if="
                addForm.type === '1001' ||
                addForm.type === '1002' ||
                addForm.type === '1003' ||
                addForm.type === '1004'
              "
              label="职称"
              prop="jobTitle"
            >
              <a-select  
                style="width: 300px"
                v-model="addForm.jobTitle"
                placeholder="请选择"
                @change="jobTitleChange"
              >
                <a-select-option
                  v-for="item in jobTitleList"
                  :value="item.jobTitle"
                >
                  {{ item.jobTitle }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-------------------------------1005------------------------------------------->
            <!-- <a-form-model-item
              label="学院"
              prop="school"
            >
              <a-select
                style="width: 300px"
                v-model="addForm.school"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in schoolListData"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="学历"
              prop="education"
            >
              <a-select
                style="width: 300px"
                placeholder="请选择"
                v-model="addForm.education"
              >
                <a-select-option
                  v-for="item in educationList"
                  :value="item.education"
                >
                  {{ item.education }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="专业"
              prop="specialty"
            >
              <a-input
                style="width: 300px"
                v-model="addForm.specialty"
                placeholder="请输入"
              />
            </a-form-model-item> -->
            <!------------------------------- 1006  ~  1007------------------------------------------->
            <a-form-model-item
              label="单位"
              prop="workUnit"
            >
              <a-input
                style="width: 300px"
                v-model="addForm.workUnit"
                placeholder="请输入"
              />
            </a-form-model-item>
            <!-- <a-form-model-item
              label="职务"
              prop="post"
            >
              <a-input
                style="width: 300px"
                v-model="addForm.post"
                placeholder="请输入"
              />
            </a-form-model-item> -->
            <!--      新增时共有的        -->
            <a-form-model-item label="性别">
              <a-select
                style="width: 300px"
                placeholder="请选择"
                v-model="addForm.sex"
              >
                <a-select-option v-for="item in sexList" :value="item.value">
                  {{ item.sex }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="邮箱">
              <a-input
                style="width: 300px"
                v-model="addForm.email"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="生日">
              <a-date-picker
                placeholder="请选择"
                style="width: 300px"
                v-model="addForm.birthday"
              />
            </a-form-model-item>
            <a-form-model-item label="认证状态">
              <a-input
                :disabled="true"
                style="width: 300px"
                v-model="addForm.certStatus"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="注册渠道">
              <a-input
                :disabled="true"
                style="width: 300px; margin-bottom: 20px"
                v-model="addForm.registerChannel"
                placeholder="请输入"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="addClose">
            取消
          </a-button>
          <a-button type="primary" @click="onSubmit"> 确认 </a-button>
        </div>
      </a-drawer>

      <!-- 已认证图片弹窗 -->
      <a-modal
        v-model="modaLshow"
        title="标记"
        @ok="handleOk"
        @cancel="handleCancel"
        width="330px"
      >
        是否完善资料：
        <a-switch
          :checked="isSummit !== 0"
          v-model="is_open"
          checked-children="是"
          un-checked-children="否"
          @click="communityListSwitch(is_open)"
          default-checked
        />
        <br>
        <span style="margin-top:20px;display: inline-block;">是否内部人员：</span> 
        <a-switch
          :checked="isStaff !== 0"
          v-model="isStaff_is_open"
          checked-children="是"
          un-checked-children="否"
          @click="community2ListSwitch(isStaff_is_open)"
          default-checked
        />
        <div style="height: 60px;"></div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  getIsStaff,putSummit,DelCancellation
} from "@/service/InternalMemberManagement_api";
import { admin_icon } from "@/utils/myIcon_f";
import moment from "moment";
import {
  postMember,
} from "@/service/MedicalConference_y";
import { educationList, sexDate } from "@/utils/dateReturn";
import City from "@/assets/city.json";
export default {
   components: {
     admin_con: admin_icon,
   },
   data(){
    return{
      isStaff:0,
      is_open:false,
      isStaff_is_open:false,
      recommendState: 0,
      modaLshow:false,
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      addVisible:false,
      sexList: sexDate(),
      hospitaloPtions: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      rules3: {
        post: [{ required: true, message: "请选择", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        workUnit: [{ required: true, message: "请输入", trigger: "change" }],
        // post: [{ required: true, message: "请输入", trigger: "change" }],
      },
      spinning:false,
      //顶部搜索
      searchForm: {
        number: "",
        name: "",
        certStatus: "",
      },
      identityValue:"",
      columns: [
          {
            title: "姓名",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "手机号码",
            dataIndex: "tel",
            key: "tel",
            align: "center",
          },
        //   {
        //     title: "单位",
        //     dataIndex: "workUnit",
        //     align: "center",
        //     // key: "tel2",
        //     // scopedSlots: { customRender: "hospitalName" },
        //   },
        //   {
        //     title: "职位",
        //     dataIndex: "post",
        //     key: "post",
        //     ellipsis: true,
        //     align: "center",
        //   },
          {
            title: "资料状态",
            dataIndex: "isSummit",
            key: "isSummit",
            align: "center",
            scopedSlots: { customRender: "isSummit" },
          },
          {
            title: "认证状态",
            dataIndex: "certStatus",
            key: "certStatus",
            ellipsis: true,
            align: "center",
            scopedSlots: { customRender: "certStatus" },
          },
          {
            title: "注册渠道",
            dataIndex: "registerChannel",
            key: "registerChannel",
            align: "center",
          },
          {
            title: "注册时间",
            dataIndex: "createdTime",
            key: "createdTime",
            align: "center",
          },
          {
            title: "操作",
            dataIndex: "operation",
            key: "address 4",
            fixed: "right",
            width: 170,
            scopedSlots: { customRender: "operation" },
          },
      ],
      tableList:[],
      pageNo:1,
      City: City,
      isSummit:0,
      memberId:'',
      addForm: {
        // type: undefined,
        name: "",
        tel: "",
        province: undefined,
        city: "",
        hospitalName: undefined,
        hospitalOffices: undefined,
        jobTitle: undefined,
        school: undefined,
        education: undefined,
        specialty: "",
        workUnit: "",
        post: undefined,
        sex: undefined,
        email: "",
        birthday: "",
        certStatus: "未认证",
        registerChannel: "后台添加",
      },
    }
   },
   created(){
     this.getList()
     this.getPCH()
   },
   methods:{
      async communityListSwitch(isOpen) {
        const response = await putSummit(this.memberId,this.isStaff,isOpen === false?0:1);
        if (response.code === 0) {
          this.$message.success("操作成功");
          this.getList()
        //   this.modaLshow = false
        } else {
          this.$message.warning(response.message);
        }
      },
    //   是否内部人员
      async community2ListSwitch(isOpen) {
        const response = await putSummit(this.memberId,isOpen === false?0:1,this.isSummit);
        if (response.code === 0) {
          this.$message.success("操作成功");
          // this.modaLshow = false
          this.getList()
        } else {
          this.$message.warning(response.message);
        }
      },
      handleOk(){
         this.modaLshow = false
      },
      handleCancel(){
        this.modaLshow = false
      },
      sign(record){
        this.modaLshow = true
        this.isSummit = record.isSummit
        this.is_open = record.isSummit === 1?true:false
        this.memberId = record.id
        this.isStaff = record.isStaff
        this.isStaff_is_open = record.isStaff === 1?true:false
      },
      async cancellation(id){
        const response = await DelCancellation(id,"admin");
        if (response.code === 0) {
          this.getList()
          this.$message.success("注销成功");
        } else {
          this.$message.warning(response.message);
        }
      },
      //获取省份城市医院
      getPCH() {
        let province = [];
        let hospitalName = [];
        this.City.forEach((item, index) => {
          let city = [];
          province.push({
            value: item.name,
            label: item.name,
            children: city,
          });
          item.cityList.forEach((list) => {
            city.push({
              value: list.name,
              label: list.name,
              loading: false,
            });
          });
          province[index].children = city;
        });
        this.hospitaloPtions = province;
      },
    provinceChange(value) {
      let data = {
        province: value[0],
        city: value[1],
      };
    //   this.getschoolList(data);
    },
    async getList() {
        this.spinning = true;
        let form = this.searchForm;
        let data = {
          name: form.name,
          tel: form.number,
          status: form.certStatus, 
          pageNo: this.pagination.current,
          pageSize:this.pagination.pageSize,
          post: this.identityValue,  
        };
        const response = await getIsStaff(data);
        if (response.code === 0) {
          this.tableList = response.data;
          this.pagination.total = response.count;
        } else {
          this.$message.warning(response.message);
        }
        this.spinning = false;
      },
    pageChange(page_no, page_size) {
      this.pagination.current = page_no
      this.pagination.pageSize = page_size
      this.getList()
    },
    enterBtn() {
      this.getList();
    },
    selectIdentityType(){
    //   this.post1 = this.identityValue
      this.getList();
    },
    selectBtn() {
      this.getList();
    },
    restBtn() {
      let form = this.searchForm;
      form.number = "";
      form.name = "";
      form.certStatus = ""
    //   this.type = 'QT'
      // this.pageNo = 1
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.identityValue = '',
      this.getList();
    },
    addBtn() {
      this.drawerTitle = "新增";
      this.addVisible = true;
    },
    resetConfirm() {
      const userId = this.user_id;
      if (userId !== "" && userId !== null && userId !== undefined) {
        this.restPass(userId);
      } else {
        this.$message.warning("请选择需要重置密码的列表");
      }
    },
    addClose() {
      this.addVisible = false;
      //清空
      this.selectedRowKeys = [];
      this.user_id = [];
      this.$refs.addRuleForm.resetFields();
      let form = this.addForm;
      form.birthday = undefined;
      form.sex = undefined;
      form.email = "";
      form.certStatus = "未认证";
      form.registerChannel = "后台添加";
      form.province = undefined
    },
    // 提交新增的表单
    onSubmit() {
      this.$refs.addRuleForm.validate((valid) => {
        if (valid) {
          let form = this.addForm;
          let data = {};
          data = {
            // type: 1008,  //1008 内部成员管理
            name: form.name,
            tel: form.tel,
            workUnit: form.workUnit,
            post: form.post,
            province: form.province[0],
            city: form.province[1],
            sex: form.sex,
            email: form.email,
            birthday: form.birthday
              ? moment(form.birthday).format("YYYY-MM-DD")
              : "",
            certStatus: form.certStatus,
            registerChannel: form.registerChannel,
            nvqUrl: "",
            isStaff:1,
          };
          //新增
          this.postList(data);
          this.addVisible = false;
          this.$refs.addRuleForm.resetFields();
          form.birthday = undefined;
          form.sex = undefined;
          form.email = "";
          form.certStatus = "未认证";
          form.registerChannel = "后台添加";
          form.province = undefined
          } else {
            return false;
          }
        });
    },
    //  新增
    async postList(data) {
      const response = await postMember(data);
      if (response.code === 0) {
        this.$message.success("新增成功");
        //  刷新列表
        await this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
   }
}
</script>

<style scoped lang="scss">
.registerMember {
  width: 98%;
  display: flex;
  .registerMember-left {
    width: 10%;
  }
  .registerMember-right {
    width: 90%;
    border-left: 15px solid #f5f5f5;
    min-height: 800px;
    .registerMember-right-top {
      background-color: #f5f5f5;
      padding-top: 15px;
      width: 100%;
      height: 120px;
      border-bottom: 10px solid #f5f5f5;
      .registerMember-right-top-input {
        // min-width: 25%;
        // height: 50px;
        // line-height: 50px;
      }
    }
    .registerMember-right-table {
      width: 100%;
      .registerMember-right-table-top-btn {
        width: 100%;
        height: 50px;
        margin-left: 20px;
        margin-top: 15px;
      }
      .registerMember-right-table-top-table {
        width: 100%;
        margin-left: 15px;
      }
    }
  }
}
.pageClass {
  width: 100%;
  text-align: right;
  float: right;
  margin: 15px 15px 20px 0;
}
.icon {
  font-size: 8px;
  margin-right: 5px;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>